<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <create-comment
            v-if="userCan('employees-add-comment')"
          :type="type"
          :typeId="typeId"
          :edit-id="editId"
          :edit-comment="editComment"
          :edit-item="currentItem"
        ></create-comment>
      </v-col>
      <v-divider class="mx-4"></v-divider>
      <v-col cols="12" v-if="items.length">
        <v-timeline >
          <template v-for="item in items">
            <v-timeline-item
              :key="item.id"
              large
              :left="_user.id!==item.user_id"
              :right="_user.id === item.user_id"
            >
              <template v-slot:icon>
                <v-avatar color="primary">
                  <v-icon dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span class="caption" v-if="item.commentator">{{
                  item.commentator.name
                }}</span>
                <span class="caption">
                  at {{ formatDate(item.updated_at, "DD.MM.YYYY HH:mm") }}</span
                >
              </template>
              <v-card class="elevation-2">
                <v-system-bar color="primary" dark>
                  <v-spacer></v-spacer>

                  <template v-if="_user.id == item.user_id">
                    <v-btn
                      icon
                      dark
                      @click="
                        editId = item.id;
                        currentItem = item;
                        editComment = item.comment;
                      "
                    >
                      <v-icon color=" white">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      dark
                      @click="
                        confirmDeleteDialog = true;
                        currentId = item.id;
                      "
                    >
                      <v-icon color=" white">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                </v-system-bar>
                <div class="text-left pa-3 body-2">
                  {{ item.comment }}
                </div>
              </v-card>
            </v-timeline-item>
          </template>
        </v-timeline>
      </v-col>
      <v-dialog v-model="confirmDeleteDialog" persistent max-width="310">
        <v-card>
          <v-card-title class="error headline white--text"
            >Confirm Delete</v-card-title
          >
          <v-card-text class="body-1 pa-3"
            >Are you sure you want to delete this comment?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              outlined
              @click.native="confirmDeleteDialog = false"
            >
              <v-icon size="20" left>mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn color="error" outlined  @click.native="deleteItem">
              <v-icon size="20" left>mdi-trash-can-outline</v-icon>Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import string from "@/mixins/string";
import _ from "lodash";
export default {
  mixins: [date, permission, string],
  components: {
    CreateComment: () => import("@/components/comments/Create")
  },
  data() {
    return {
      confirmDeleteDialog: false,
      currentId: null,
      currentItem: null,
      editId: null,
      editComment: null
    };
  },
  name: "CommentsList",
  props: {
    type: {
      type: String,
      required: true
    },
    typeId: {
      type: [String, Number],
      required: true
    },
    unreadCommentList: {
      type: Array,
      required: false,
      default:() => []
    }
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["comments/loading"];
      }
    },
    apiData() {
      return {
        type: this.type,
        typeId: this.typeId
      };
    },
    items: {
      get() {
        return this.$store.getters["comments/item"];
      },
      set() {
        this.$store.dispatch("comments/getItem", this.apiData);
      }
    },
    _user() {
      return JSON.parse(this.$auth.remember()) || {};
    }
  },
  async mounted() {
    await this.getData();
    console.log(this.unreadCommentList)
    if(this.unreadCommentList.length){
      this.$store.dispatch("comments/markAsSeen", {comments:this.unreadCommentList,user_id:this._user.id});

    }
  },
  methods: {
    deleteItem() {
      const _this = this;
      _this.$store.dispatch("comments/deleteItem", this.currentId).then(() => {
        this.confirmDeleteDialog = false;
        this.currentId = null;
        this.getData();
      });
    },
    async getData() {
      await this.$store.dispatch("comments/getItem", this.apiData);
    }
  }
};
</script>
